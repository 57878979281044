<template>
  <div class="flex antialiased bg-f-white hj:bg-f-bg" :class="{ 'flex-col': isMobile && !fixFooter }">
    <div class="flex-1 scroll-touch">
      <div class="w-full min-h-screen pt-8 pb-32 relative" :class="{ 'pb-64': isMobile && fixFooter }">
        <div v-if="isMobile">
          <div class="flex items-center justify-end md:justify-end w-full -mt-6 mb-4 md:mb-4">
            <HeaderMenu />
          </div>
          <div
            class="w-full flex items-center justify-center md:justify-start border-b border-b-gray-400 hj:border-white/60 pb-8">
            <div class="w-6/12 md:w-3/12 lg:w-2/12 pl-0 md:pl-8">
              <logo />
            </div>
          </div>
          <div class="w-full flex items-center justify-start border-b border-b-gray-400 hj:border-white/60 py-2.5">
            <div class="flex items-center justify-between md:justify-start">
              <div
                v-if="showBackButton"
                class="relative rounded-full w-8 h-8 ml-4 md:ml-0 mr-4 border border-gray-400 cursor-pointer"
                @click="back">
                <div class="absolute absolute-center">
                  <img :src="asset('assets/chevron.svg')" alt="back icon" class="bo:hidden w-3 -rotate-90" />
                  <img :src="asset('assets/chevron-dark.svg')" alt="back icon" class="hidden bo:block w-3 -rotate-90" />
                </div>
              </div>
              <h1
                v-if="pageTitle"
                :class="{ 'ml-4': isMobile && !showBackButton }"
                class="bo:font-graphik-bold hj:font-druk-heavy-italic text-xl hj:text-2xl md:text-3xl md:hj:text-4xl text-center text-f-bg hj:text-f-white">
                {{ pageTitle }}
              </h1>
            </div>
          </div>
        </div>
        <div v-if="!isMobile">
          <div
            class="w-full flex items-center justify-center md:justify-start border-b border-b-gray-400 hj:border-white/60 pb-8">
            <div class="w-8/12 md:w-3/12 lg:w-2/12 pl-0 md:pl-8">
              <logo />
            </div>
            <div class="w-full md:w-8/12 flex items-center justify-between md:ml-4">
              <div class="flex items-center justify-between md:justify-start">
                <div
                  v-if="showBackButton"
                  class="relative rounded-full w-10 h-10 ml-4 md:ml-0 mr-4 border border-gray-400 cursor-pointer"
                  @click="back">
                  <div class="absolute absolute-center">
                    <img :src="asset('assets/chevron.svg')" alt="back icon" class="bo:hidden w-3 -rotate-90" />
                    <img
                      :src="asset('assets/chevron-dark.svg')"
                      alt="back icon"
                      class="hidden bo:block w-3 -rotate-90" />
                  </div>
                </div>
                <h1
                  v-if="pageTitle"
                  :class="{ 'ml-4': isMobile && !showBackButton }"
                  class="bo:font-graphik-bold hj:font-druk-heavy-italic text-xl hj:text-2xl md:text-3xl md:hj:text-4xl text-center text-f-bg hj:text-f-white">
                  {{ pageTitle }}
                </h1>
              </div>
              <button
                :disabled="disableButton"
                v-if="buttonText && !loadingButton"
                type="button"
                class="bo:font-graphik-medium hj:font-druk-bold hj:text-2xl bg-f-contrast font-bold hj:font-normal rounded-full hj:rounded-xl w-full text-f-tertiary hover:text-f-white py-3 md:w-auto px-5 hover:bg-f-tertiary hover:hj:bg-f-secondary"
                @click="handleClickedHeaderButton">
                {{ buttonText }}
              </button>
              <loading-button
                v-if="buttonText && loadingButton"
                :text="computedMobileButtonText"
                loading-text="Processing"
                type="button"
                :loading="isLoading"
                class="bo:font-graphik-medium hj:font-druk-bold bg-f-contrast font-bold hj:font-normal rounded-full hj:rounded-xl w-full text-f-tertiary hover:text-f-white py-3 md:w-auto px-5 enabled:hover:bg-f-tertiary enabled:hover:hj:bg-f-secondary"
                @button-click="handleClickedHeaderButton" />
            </div>
            <div class="relative w-4/12 2xl:w-3/12 self-start -mt-6">
              <HeaderMenu />
            </div>
          </div>
        </div>
        <alert :flash="alertError" :dismissible="false" class="mt-4 mx-4" />
        <slot />
        <GdprBanner @show-banner="showBanner" />
<!--        <div-->
<!--          class="fixed w-full h-full top-0 left-0 pointer-events-none select-none"-->
<!--          :class="{ 'backdrop-blur bg-f-bg/20 hj:bg-transparent z-60': showOverlay || showGdprBanner }"></div>-->
      </div>
    </div>
    <aside
      v-if="showFooter && isMobile"
      :class="{ fixed: isMobile && fixFooter }"
      class="fixed w-full px-5 py-6 bottom-0 left-0 w-full bg-f-white border-t-2 border-t-f-bg z-40">
      <div class="flex items-center justify-between">
        <div :class="{ 'w-5/12 mr-5': computedMobileButtonText, 'w-full': !computedMobileButtonText }">
          <slot name="mobileFooter" />
        </div>
        <button
          :disabled="disableButton"
          v-if="computedMobileButtonText && !loadingButton"
          type="button"
          class="bo:font-graphik-medium hj:font-druk-bold hj:text-f-white hj:text-2xl bg-f-contrast font-bold hj:font-normal rounded-full hj:rounded-xl text-f-tertiary hover:text-f-white py-3 w-auto px-5 hover:bg-f-tertiary hover:hj:bg-f-secondary"
          @click="handleClickedHeaderButton">
          {{ computedMobileButtonText }}
        </button>
        <loading-button
          :disabled="disableButton"
          v-if="computedMobileButtonText && loadingButton"
          :text="computedMobileButtonText"
          loading-text="Processing"
          type="button"
          :loading="isLoading"
          class="bo:font-graphik-medium hj:font-druk-bold hj:text-f-white bg-f-contrast font-bold hj:font-normal rounded-full hj:rounded-xl text-f-tertiary hover:text-f-white py-3 md:w-auto px-5 enabled:hover:bg-f-tertiary ednabled:hover:hj:bg-f-secondary"
          :style="{ opacity: disableButton ? '0.2' : '1' }"
          @button-click="handleClickedHeaderButton" />
      </div>
      <slot name="mobileFooterBottom" />
    </aside>
    <aside
      v-if="
        (showFooter && !isMobile && (parent === 'ShowAddonsForm' || parent === 'Payment')) ||
        ((parent === 'ShowAddonsForm' || parent === 'Payment') && cartToLong)
      "
      class="w-full px-4 py-4 fixed bottom-0 left-0 bg-f-white border-t-2 border-t-f-bg/[.07] z-60">
      <div class="w-full flex items-center justify-end gap-4">
        <slot name="laptopFooter" />
        <button
          :disabled="disableButton"
          v-if="computedMobileButtonText && !loadingButton"
          type="button"
          class="bo:font-graphik-medium hj:font-druk-bold hj:text-2xl bg-f-contrast font-bold hj:font-normal rounded-full hj:rounded-xl grow md:grow-0 text-f-tertiary hj:text-f-white hover:text-f-white py-3 w-auto px-5 hover:bg-f-tertiary hover:hj:bg-f-secondary"
          @click="handleClickedHeaderButton">
          {{ computedMobileButtonText }}
        </button>
        <loading-button
          v-if="computedMobileButtonText && loadingButton"
          :text="computedMobileButtonText"
          loading-text="Processing"
          type="button"
          :loading="isLoading"
          class="bo:font-graphik-medium hj:font-druk-bold hj:text-f-white bg-f-contrast font-bold hj:font-normal rounded-full hj:rounded-xl w-full text-f-tertiary hover:text-f-white py-3 md:w-auto px-5 enabled:hover:bg-f-tertiary enabled:hover:hj:bg-f-secondary"
          @button-click="handleClickedHeaderButton" />
      </div>
    </aside>
  </div>
</template>

<script>
import ThemeToggle from "../components/ThemeToggle.vue"
import Logo from "../components/Logo.vue"
import { Link } from "@inertiajs/vue3"
import HeaderMenu from "../components/HeaderMenu.vue"
import LoadingButton from "../components/LoadingButton.vue"
import Alert from "../components/Alert.vue"
import GdprBanner from "../components/GdprBanner.vue"
export default {
  name: "WithHeader",
  components: {
    GdprBanner,
    Alert,
    HeaderMenu,
    ThemeToggle,
    Link,
    Logo,
    LoadingButton,
  },
  props: {
    pageTitle: String,
    buttonText: String,
    buttonMobileText: String,
    showBackButton: Boolean,
    disableButton: {
      type: Boolean,
      default: false,
    },
    fixFooter: {
      type: Boolean,
      default: true,
    },
    loadingButton: {
      type: Boolean,
      default: false,
    },
    isLoading: Boolean,
    showFooter: Boolean,
    showOverlay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      componentKey: 0,
      showGdprBanner: false,
    }
  },
  computed: {
    computedMobileButtonText() {
      return this.buttonText ?? this.buttonMobileText
    },
    continueButtonInViewport() {
      return this.$store.state.addonPaymentContinueButtonInViewport
    },
    cartToLong() {
      return Object.values(this.$store.state.cart.combined.lines).length > 8
    },
    alertError() {
      const keys = Object.keys(this.$page.props.errors)
      if (keys.length > 0 && keys[0] === "error") {
        return {
          message: this.$page.props.errors?.["error"],
          type: "error",
        }
        return null
      }
    },
    parent() {
      return this.$parent.$options.name
    },
  },
  methods: {
    showBanner(event) {
      this.showGdprBanner = event
      this.$emit("show-gdpr-banner")
    },
    back() {
      // if(this.parent === 'ShowAddonsForm') {
      //   this.$store.dispatch('updateNextStep', false)
      // }
      window.history.back()
    },
    handleClickedHeaderButton() {
      if (this.disableButton) return
      this.$emit("clicked-header-button")
    },
  },
  updated() {
    this.componentKey++
  },
  created() {
    this.identifyWithCustomerIoWithCioId()
  },
}
</script>
